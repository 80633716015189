import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Home.css';
import logo from '../assets/alblasserwaardsche_logo.svg';

const Home = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="home-container">
      <div className="header1">
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      <div className="home-overlay">
      <img src={logo} alt="Alblasserwaardsche" className="logo-image" />
        <h1>Welkom op het intranet</h1>
        <div className="button-grid">
        <a 
            className="home-button" 
            href="https://lookerstudio.google.com/u/0/reporting/6538c5a3-c18e-4a4f-9d74-318c3782aa3c/page/hFKFD" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Dashboard
          </a>
          <a 
            className="home-button" 
            href="https://docs.google.com/spreadsheets/d/1akig4nkMeU5jB4WaHu4zX2x6OY7sMTv2WCv5DdiSABg/edit?usp=sharing" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Vakantieplanner
          </a>
          <a 
            className="home-button" 
            href="https://docs.google.com/spreadsheets/d/1IZJ5dzehsFrcnlnzBxa232pcsUlvOTLGn1f41vO4LXU/edit?usp=sharing" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Dagplanning
          </a>
          <a 
            className="home-button" 
            href="https://docs.google.com/document/d/1OyIs6onaiddm869VQawJGu4abADSUIB0BQO1I7uIY-E/edit?usp=sharing" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Instructies
          </a>
        
          <a 
            className="home-button" 
            href="https://script.google.com/a/macros/adaptto.nl/s/AKfycbws0zkC5VVeQTzmal4_F_Km878MlpaNLOHFL2vRsJltmgZanmMQxWjWwELayNwUAHMN-w/exec"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Schade Assistent
          </a>

          <a 
            className="home-button" 
            href="https://drive.google.com/drive/folders/1NdnQrF_3NJMtxq-PtwivqkjsgjcDYqu_?usp=sharing"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Formulieren
          </a>
        
          <button className="home-button" onClick={() => navigate('/calls')}>Extra </button>
 
        </div>
      </div>
    </div>
  );
};

export default Home;
