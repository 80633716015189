import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import MainPage from './components/MainPage';
import VoysCalls from './components/Voyscalls'; 

const PrivateRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('token');
  return (
    <Route
      {...rest}
      element={isAuthenticated ? <Element /> : <Navigate to="/login" />}
    />
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        
        {/* Protect the home route */}
        <Route 
          path="/home" 
          element={!!localStorage.getItem('token') ? <MainPage /> : <Navigate to="/login" />} 
        />

        {/* Protect the Voys calls route */}
        <Route 
          path="/calls" 
          element={!!localStorage.getItem('token') ? <VoysCalls /> : <Navigate to="/login" />} 
        />
      
        {/* Redirect any unknown routes to login */}
        <Route 
          path="*" 
          element={<Navigate to="/login" />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
